body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.background-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('https://img.freepik.com/free-vector/white-color-background-gradient-abstract-modern_343694-2130.jpg?w=360');
  background-size: cover;
  opacity: 0.9;
  z-index: -1;
}

.container-content {
  z-index: 1;
  position: relative;
}

/* .ui.tabular.menu .item {
  color: white !important;
}
.ui.tabular.menu .active.item {
  color: black !important;
} */